import React from "react";
import styles from "./Construction.module.scss";
import {Stack} from "@fluentui/react";

import IsaacLoading from "../../components/isaacLoading/IsaacLoading";
import useConstructionPageHook from "./hooks/useConstructionPageHook";
import ConstructionHeader from "./components/header/ConstructionHeader";
import ConstructionBody from "./components/body/ConstructionBody";

const Construction: React.FunctionComponent = () => {
  const {construction, loading} = useConstructionPageHook();
  return (
    <>
      <IsaacLoading loading={loading} message="Loading construction data..." />
      {!loading && (
        <Stack className={styles.bodyStack}>
          <Stack.Item>
            <ConstructionHeader construction={construction} />
          </Stack.Item>
          <Stack.Item>
            <ConstructionBody construction={construction} />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default Construction;
