import React, {useEffect, useState} from "react";
import {Stack} from "@fluentui/react";

import styles from "./EditablePropertiesPanel.module.scss";
import IsaacList from "../../../../components/isaacList/IsaacList";
import useEditablePropertyHook from "../body/hooks/useEditablePropertyHook";
import type EditablePropertiesPanelProps from "./EditablePropertiesPanelProps";
import type RenderableEditableProperty from "../../../../types/RenderableEditableProperty";
import type {EditableProperty} from "../../../../types/EditableConstruction";

const EditablePropertiesPanel: React.FunctionComponent<
  EditablePropertiesPanelProps
> = ({profileCalculationResults, construction}) => {
  const mapEditablePropsToRenderableEditableProps = useEditablePropertyHook();
  const [items, setItems] = useState<RenderableEditableProperty[]>([]);

  useEffect(() => {
    if (construction !== undefined) {
      const editableProps: EditableProperty[] =
        profileCalculationResults?.editableConstructionProperties ?? [];

      const result = mapEditablePropsToRenderableEditableProps(
        construction,
        editableProps,
      );
      setItems(result);
    }
  }, [profileCalculationResults]);

  return (
    <>
      {items?.length > 0 && (
        <Stack grow={1} className={`${styles.container} page-brake-after`}>
          {/* TODO: Move all text into translation files */}
          <h3 className={styles.header}>Editable properties</h3>
          <IsaacList<RenderableEditableProperty>
            items={items}
            onRenderCell={item => (
              <>
                {`${item?.entity} - ${item?.property} :`}{" "}
                <s>{item?.oldValue}</s> <b>{item?.currentValue}</b>
              </>
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default EditablePropertiesPanel;
