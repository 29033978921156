import React from "react";
import {Icon} from "@fluentui/react";
import type {Dispatch, UnknownAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";
import {switchMast} from "../../constructionCalculationsSlice";
import type ICalculationConstructionDetails from "../../../../types/ICalculationConstructionDetails";

// Note: This this refactoring. Most of the logic has just been ported and not adjusted
const CalculationMastTabs: React.FunctionComponent<{
  selectedMastNo: string | undefined;
  constructionDetails: ICalculationConstructionDetails;
  profileCalculationResults: any;
}> = ({selectedMastNo, constructionDetails, profileCalculationResults}) => {
  const dispatch = useDispatch();
  const profilesAreValid: boolean = profileCalculationResults.profilesAreValid;
  const mastResult: any[] = profileCalculationResults.mastResults;
  return (
    <>
      {renderResultsTabs(
        profilesAreValid,
        selectedMastNo,
        constructionDetails,
        mastResult,
        dispatch,
      )}
    </>
  );
};

// Note: This needs refactoring. Most of the logic has just been ported and not adjusted
const renderResultsTabs = (
  profilesAreValid: boolean,
  selectedMastNo: string | undefined,
  constructionDetails: ICalculationConstructionDetails,
  mastResult: any[],
  dispatch: Dispatch<UnknownAction>,
): any => {
  const results: any[] = [];
  const subResults: any[] = [];

  if (constructionDetails === null || constructionDetails === undefined) {
    return results;
  }

  const {masts, beams} = constructionDetails;

  const maxLength =
    mastResult.length > beams.length ? mastResult.length : beams.length;

  for (let i = 0; i < maxLength; ++i) {
    const foundationType = masts.find(
      m => m.number === mastResult[i]?.mastNumber,
    )?.foundationType;
    const mastDetails =
      i < mastResult.length ? {...mastResult[i], ...masts[i]} : null;
    const beamDetails = i < beams.length ? beams[i] : null;
    const subSubResults = [];

    if (mastDetails !== null) {
      subSubResults.push(
        <div
          key={`${i}-${mastDetails.mastNumber}`}
          className={
            selectedMastNo !== null && mastDetails.mastNumber === selectedMastNo
              ? `${styles.mastBtn} ${styles.selected}`
              : styles.mastBtn
          }
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            dispatch(switchMast(mastDetails.mastNumber));
          }}>
          <span className={profilesAreValid ? styles.valid : styles.notValid}>
            {mastDetails.mastNumber}
          </span>
          <span className={profilesAreValid ? styles.valid : styles.notValid}>
            {mastDetails.profileType}
          </span>
          <span style={{color: "#000"}}>{foundationType}</span>
        </div>,
      );
    }

    if (beamDetails !== null) {
      subSubResults.push(
        <div key={`${i}-${beamDetails.type}`} className={styles.beam}>
          <span>{beamDetails.type}</span>
        </div>,
      );
    }

    if (mastDetails !== null && beamDetails !== null) {
      if (mastDetails.position * 2 - 1 >= beamDetails.compartment * 2) {
        subSubResults.reverse();
      }
    }

    subResults.push(...subSubResults);
  }

  subResults.forEach((element, index) => {
    results.push(element);
    if (index !== subResults.length - 1) {
      results.push(
        <div key={index} className={styles.separator}>
          <Icon iconName="Remove" />
        </div>,
      );
    }
  });

  return results;
};

export default CalculationMastTabs;
