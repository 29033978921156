import {saveAs} from "file-saver";

import useApi from "../services/api/hooks/useApi";
import ApiHttpVerbs from "../services/api/types/ApiHttpVerbs";
import Api from "../services/auth/types/IsaacApi";
import {getICCConstructions} from "../pages/constructions/legacy/LegacyCode";
import type ApiResponse from "../services/api/types/ApiResponse";
import type IConstructionHook from "../types/IConstructionHook";
import type IConstruction from "../pages/construction/types/IConstruction";
import type {EditableProperty} from "../types/EditableConstruction";
import type {IDefaultConstructionCalculationParameters} from "../types/IDefaultConstructionCalculationParameters";
import type {EditableAndDeselectedElementsList} from "../types/EditableAndDeselectedElementsList";

/** Hook that defines all BE calls for the construction entity. */
export const useConstructionHook = (): IConstructionHook => {
  const {fetchData} = useApi();

  const getAllConstructions = async (): Promise<ApiResponse<any[]>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions`;
    const response = await fetchData<any[]>(url, Api.ICC);

    return {
      ...response,
      data: response?.data != null ? getICCConstructions(response.data) : null,
    };
  };

  const getConstructionById = async (
    id: string,
  ): Promise<ApiResponse<IConstruction>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${id}/gui`;
    const response = await fetchData<IConstruction>(url, Api.ICC);

    return response;
  };

  const deleteConstructionById = async (
    constructionId: string,
    correlationGuid: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}`;
    const response = await fetchData<any>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.DELETE,
      {
        "X-Correlation-ID": correlationGuid,
      },
    );
    return response;
  };

  const downloadExcelInputMatrixById = async (
    constructionId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/constructions/${constructionId}/inputmatrix`;
    const response = await fetchData<any>(url, Api.ISAAC, false);

    if (
      response.success &&
      response.data !== null &&
      response.data.blob !== null
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      saveAs(response.data.blob, response.data.fileName);
    }

    return response;
  };

  const downloadJsonInputMatrixById = async (
    constructionId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/constructions/${constructionId}/inputmatrixjson`;
    const response = await fetchData<any>(url, Api.ISAAC, false);

    if (
      response.success &&
      response.data !== null &&
      response.data.blob !== null
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      saveAs(response.data.blob, response.data.fileName);
    }

    return response;
  };

  const validateEditConstructionGeometrically = async (
    constructionId: string,
    collection: EditableAndDeselectedElementsList,
  ): Promise<ApiResponse<boolean>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/editConstructionValidateGeometrically`;
    const response = await fetchData<boolean>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify(collection),
    );
    return response;
  };

  const getDefaultConstructionCalculationParameters = async (): Promise<
    ApiResponse<IDefaultConstructionCalculationParameters>
  > => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/defaultCalculationParameters`;
    const response = await fetchData<IDefaultConstructionCalculationParameters>(
      url,
      Api.ICC,
      false,
    );

    return response;
  };

  const saveModifiedConstruction = async (
    constructionId: string,
    calculationId: string,
    editableProperties: EditableProperty[],
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/constructions/${constructionId}/saveModified`;
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify({editableProperties, calculationId}),
    );
    return response;
  };

  const freezeConstructionById = async (
    constructionId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/freeze`;
    const response = await fetchData<any>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.POST,
    );

    return response;
  };
  return {
    getAllConstructions,
    getConstructionById,
    deleteConstructionById,
    downloadExcelInputMatrixById,
    downloadJsonInputMatrixById,
    validateEditConstructionGeometrically,
    getDefaultConstructionCalculationParameters,
    saveModifiedConstruction,
    freezeConstructionById,
  };
};

export default useConstructionHook;
