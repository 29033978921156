import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {Stack} from "@fluentui/react";

import styles from "./ConstructionCalculations.module.scss";
import ConstructionCalculationsHeader from "./components/header/ConstructionCalculationsHeader";
import ConstructionCalculationsBody from "./components/body/ConstructionCalculationsBody";
import ConstructionCalculationsPanel from "./components/sidePanel/ConstructionCalculationsPanel";
import IsaacLoading from "../../components/isaacLoading/IsaacLoading";
import {MessageType} from "../../services/signalR/models/Messages";
import useConstructionCalculationsPageHook from "./hooks/useConstructionCalculationsPageHook";
import {clear as clearConstructionCalculations} from "./constructionCalculationsSlice";
import {clear as clearConstruction} from "../construction/constructionSlice";
import {markAsRead} from "../layout/messagesSlice";
import type IConstruction from "../construction/types/IConstruction";

// Note: this does not take into account that foundations and footplates will also have wildcard
const isConstructionModified = (
  construction: IConstruction | undefined,
  isAllowToTakeAction: boolean,
  selectedCalculationResults: any,
): boolean => {
  if (construction !== undefined) {
    const originalRequestHasMastWildCard = construction.masts?.some(
      m =>
        m.profile?.includes("?") ||
        selectedCalculationResults?.editableConstructionProperties?.length > 0,
    );

    return isAllowToTakeAction && originalRequestHasMastWildCard;
  }
  return false;
};

const ConstructionCalculations: React.FunctionComponent = () => {
  const {
    messages,
    construction,
    calculations,
    selectedMastNo,
    selectedCalculationResults,
    constructionDetails,
    isAllowToTakeAction,
    loadPageData,
    handleSaveModifiedConstruction,
    handleNavigateToCalculationsNote,
  } = useConstructionCalculationsPageHook();

  const id = useParams();
  const {state} = useLocation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const areProfilesValid =
    selectedCalculationResults?.profilesAreValid ?? false;

  const showSaveModifiedBtn = isConstructionModified(
    construction,
    isAllowToTakeAction,
    selectedCalculationResults,
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      setLoading(true);
      await loadPageData(state?.calculationId);
      setLoading(false);
    })();
    return () => {
      dispatch(clearConstruction());
      dispatch(clearConstructionCalculations());
    };
  }, [id]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const relatedMessages = messages.filter(
        m => m.data?.constructionId !== id && !m.isRead,
      );
      if (relatedMessages.length > 0) {
        const firstUnreadMessage = relatedMessages[0];
        if (
          firstUnreadMessage?.type === MessageType.CalculationCompleted ||
          firstUnreadMessage?.type === MessageType.ConstructionUpdated
        ) {
          setLoading(true);
          setOpen(false);
          dispatch(markAsRead(firstUnreadMessage.correlationId ?? ""));
          await loadPageData(
            firstUnreadMessage.data?.id ?? state?.calculationId,
          );
          setLoading(false);
        }
      }
    })();
  }, [messages]);

  return (
    <>
      <IsaacLoading
        loading={loading}
        message="Loading construction calculation..."
      />
      {!loading && (
        <Stack className={styles.bodyStack}>
          <Stack.Item>
            <ConstructionCalculationsHeader
              profilesAreValid={areProfilesValid}
              setCalculationPanelState={() => {
                setOpen(prevState => !prevState);
              }}
              handleSaveModifiedConstruction={handleSaveModifiedConstruction}
              handleNavigateToCalculationsNote={
                handleNavigateToCalculationsNote
              }
              showSaveModifiedBtn={showSaveModifiedBtn}
              calculations={calculations}
              construction={construction}
              selectedCalculationResults={selectedCalculationResults}
            />
          </Stack.Item>
          <Stack.Item>
            <ConstructionCalculationsBody
              selectedMastNo={selectedMastNo}
              construction={construction}
              constructionDetails={constructionDetails}
              profileCalculationResults={selectedCalculationResults}
            />
          </Stack.Item>
          <ConstructionCalculationsPanel
            open={open}
            setCalculationPanelState={() => {
              setOpen(prevState => !prevState);
            }}
            calculations={calculations}
            isAllowToTakeAction={isAllowToTakeAction}
          />
        </Stack>
      )}
    </>
  );
};

export default ConstructionCalculations;
