import React, {useEffect, useState} from "react";
import {Stack} from "@fluentui/react";
import {useLocation} from "react-router-dom";

import IsaacLoading from "../../components/isaacLoading/IsaacLoading";
import useConstructionCalculationsPageHook from "../constructionCalculations/hooks/useConstructionCalculationsPageHook";
import ConstructionCalculationsBody from "../constructionCalculations/components/body/ConstructionCalculationsBody";
import ConstructionBody from "../construction/components/body/ConstructionBody";
import IsaacPills from "../../components/isaacPills/IsaacPills";
import IsaacPdfViewer from "../../components/isaacPdfViewer/IsaacPdfViewer";
import useLoadPdfFromBlob from "./useLoadPdfFromBlob";

const ConstructionCalculationsNote: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const {state} = useLocation();
  const {result: url} = useLoadPdfFromBlob(
    "Calculation_Notes_Static_Data_v1.0.pdf",
  );

  const {
    loadPageData: loadingCalculationsData,
    construction,
    constructionDetails,
    selectedCalculationResults,
  } = useConstructionCalculationsPageHook();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await loadingCalculationsData(state?.calculationId);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <IsaacLoading loading={loading} message="Loading calculations note..." />
      {!loading && (
        <Stack>
          {construction != null && (
            <Stack.Item>
              <IsaacPills
                pills={[
                  {name: "Name", value: construction.name},
                  {name: "Location type", value: construction.locationType},
                  {name: "Location code", value: construction.locationCode},
                ]}
              />
            </Stack.Item>
          )}
          <Stack.Item>
            {constructionDetails?.masts.map(mast => (
              <ConstructionCalculationsBody
                key={mast.number}
                construction={construction}
                constructionDetails={constructionDetails}
                selectedMastNo={mast.number}
                profileCalculationResults={selectedCalculationResults}
              />
            ))}
          </Stack.Item>
          <Stack.Item>
            <h2>Construction data</h2>
            <ConstructionBody construction={construction} />
          </Stack.Item>

          {url && (
            <Stack.Item>
              <IsaacPdfViewer url={url} />
            </Stack.Item>
          )}
        </Stack>
      )}
    </>
  );
};

export default ConstructionCalculationsNote;
